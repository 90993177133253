import React, { useCallback, useEffect, useState } from 'react';
import { App, Button, Dropdown, Input, Table, Typography } from 'antd';
import { MainContent } from '../../components/MainContent';
import { useMutation, useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { PageHeader } from '@ant-design/pro-components';
import {
  CloudUploadOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../components/Markable';
import { tableActionCell } from '../../styles/globalCss';
import { ItemType } from 'antd/es/menu/interface';
import { ProfileEditorDrawer } from './profiles/ProfileEditorDrawer';
import { CopyProfileModal } from './profiles/CopyProfileModal';
import { ImportProfileModal } from './profiles/ImportProfileModal';
import { graphql } from '../../graphql/generated';
import { ProfilesQuery } from '../../graphql/generated/graphql.ts';
import { UpdateEvent } from '../../socket/types.ts';
import { useSubscription } from '../../socket/useSubscription.ts';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';

const PROFILES_QUERY = graphql(`
  query Profiles($doctorId: ID!) {
    profiles(doctorId: $doctorId) {
      id
      name
      hotKey
      parameters {
        id
        parameter {
          id
          shortName
          longName
          lab {
            id
            shortName
          }
        }
        preferredBillingType
      }
      diagnoses {
        id
        text
        lab {
          id
          shortName
        }
      }
      diagnose
    }

    catalogParameters(doctorId: $doctorId) {
      id
      parameter {
        id
        shortName
        longName
        lab {
          id
          shortName
        }
        synonyms
      }
    }

    diagnoses(doctorId: $doctorId) {
      id
      text
      lab {
        id
        shortName
      }
    }

    doctorsForCurrentUser {
      id
      name
      meAddress
      firstName
      lastName
      postTitle
      preTitle
      salutation
      disabled
    }

    doctor(id: $doctorId) {
      id
      flipParamNames
    }
  }
`);

const DELETE_PROFILE_MUTATION = graphql(`
  mutation DeleteProfile($id: ID!) {
    deleteProfile(id: $id)
  }
`);

export type Profile = NonNullable<ProfilesQuery['profiles']>[number];
export type Doctor = NonNullable<ProfilesQuery['doctorsForCurrentUser']>[number];
export type CatalogParameter = NonNullable<ProfilesQuery['catalogParameters']>[number];
export type Diagnose = NonNullable<ProfilesQuery['diagnoses']>[number];

export const Profiles: React.FC = () => {
  const { currentDoctorId } = useCurrentContextStore();

  const [importModalOpen, setImportModalOpen] = useState(false);
  const [editProfile, setEditProfile] = useState<Profile | null>(null);
  const [copyProfile, setCopyProfile] = useState<Profile | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredProfiles, setFilteredProfiles] = useState<Profile[]>([]);
  const { modal, message } = App.useApp();

  const { data, loading, refetch } = useQuery(PROFILES_QUERY, {
    variables: {
      doctorId: currentDoctorId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const callback = useCallback(
    (event: UpdateEvent) => {
      console.debug('received UI update: ' + JSON.stringify(event));
      refetch();
    },
    [refetch]
  );

  useSubscription({ type: 'profile-created', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'profile-updated', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'profile-deleted', tenantId: currentDoctorId }, callback);

  const [deleteProfileMutation] = useMutation(DELETE_PROFILE_MUTATION);
  const deleteProfile = async (profile: Profile) => {
    modal.confirm({
      maskClosable: true,
      title: 'Profil Löschen',
      content: (
        <>
          Wollen Sie das Profil <Typography.Text strong>{profile.name}</Typography.Text> wirklich löschen?
        </>
      ),
      okText: 'Löschen',
      cancelText: 'Abbrechen',
      okButtonProps: { icon: <DeleteOutlined /> },
      onOk: async () => {
        try {
          await deleteProfileMutation({
            variables: {
              id: profile.id,
            },
          });

          message.success('Profil wurde gelöscht');
        } catch (e) {
          message.error('Profil konnte nicht gelöscht werden');
        }
      },
    });
  };

  const columns: ColumnsType<Profile> = [
    {
      title: 'Bezeichnung',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: value => (
        <Typography.Text style={{ fontWeight: 500 }}>
          <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>
        </Typography.Text>
      ),
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Tastaturkürzel',
      dataIndex: 'hotKey',
      key: 'hotKey',
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Anzahl Parameter',
      key: 'paramCount',
      ellipsis: true,
      width: 150,
      render: (_, record) => record.parameters.length,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => {
        const items: ItemType[] = [
          {
            label: 'Bearbeiten',
            key: 'edit',
            icon: <EditOutlined />,
            onClick: () => setEditProfile(record),
          },
          {
            label: 'Kopieren',
            key: 'copy',
            icon: <CopyOutlined />,
            onClick: () => setCopyProfile(record),
          },
          {
            label: 'Löschen',
            danger: true,
            key: 'delete',
            icon: <DeleteOutlined />,
            onClick: () => deleteProfile(record),
          },
        ];

        return (
          <Dropdown menu={{ items: items }} trigger={['click']} placement="bottomRight">
            <Button icon={<EllipsisOutlined style={{ fontSize: '20px' }} />} type="text" />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    setFilteredProfiles(
      data?.profiles?.filter(it => {
        if (debouncedSearch && debouncedSearch.length) {
          const searchValue = debouncedSearch.toLowerCase();
          return it.name.toLowerCase().includes(searchValue);
        }
        return true;
      }) ?? []
    );
  }, [debouncedSearch, data]);

  const usedHotKeys = data?.profiles?.filter(it => it.hotKey)?.map(it => it.hotKey!) ?? [];

  return (
    <MainContent size="medium">
      <PageHeader
        title="Profile"
        style={{ padding: 0, paddingBottom: 'inherit' }}
        extra={[
          <Input
            key="search"
            allowClear
            autoFocus
            placeholder="Suche nach Bezeichnung"
            value={search ?? ''}
            onChange={e => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ width: '250px' }}
          />,
          <Button key="import" icon={<CloudUploadOutlined />} onClick={() => setImportModalOpen(true)}>
            Profile importieren
          </Button>,
          <Button
            key="create"
            icon={<PlusCircleOutlined />}
            type="primary"
            onClick={() => {
              setEditProfile({
                id: '',
                name: '',
                parameters: [],
                hotKey: null,
                diagnose: '',
                diagnoses: [],
              });
            }}
          >
            Neues Profil anlegen
          </Button>,
        ]}
      />
      <Table<Profile>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        sticky={true}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredProfiles}
        pagination={false}
        loading={loading}
        columns={columns}
      />
      <CopyProfileModal
        doctors={data?.doctorsForCurrentUser ?? []}
        currentDoctorId={currentDoctorId}
        profile={copyProfile}
        onClose={() => setCopyProfile(null)}
        onSuccess={() => {
          setCopyProfile(null);
        }}
      />
      <ProfileEditorDrawer
        doctorId={currentDoctorId}
        usedHotKeys={usedHotKeys}
        catalogParameters={data?.catalogParameters ?? []}
        diagnoses={data?.diagnoses ?? []}
        profile={editProfile}
        flipParamNames={data?.doctor?.flipParamNames ?? false}
        onClose={() => setEditProfile(null)}
        onSuccess={() => {
          setEditProfile(null);
        }}
      />
      <ImportProfileModal open={importModalOpen} onClose={() => setImportModalOpen(false)} />
    </MainContent>
  );
};
