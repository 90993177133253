import React from 'react';
import { LgBadge } from './LgBadge';
import { translateBillingType } from '../utils/enumHelpers';
import { BillingType } from '../graphql/generated/graphql.ts';

export const BillingTypeWithBadge: React.FC<{ billingType: BillingType }> = ({ billingType }) => {
  switch (billingType) {
    case BillingType.LG_DIAGNOSE:
      return (
        <>
          <LgBadge /> Kassa-Diagnose
        </>
      );
    case BillingType.LG_INSURANCE:
      return (
        <>
          <LgBadge /> Kassa
        </>
      );
    case BillingType.LG_DOCTOR:
      return (
        <>
          <LgBadge /> Zuweiser
        </>
      );
    default:
      return <>{translateBillingType(billingType)}</>;
  }
};
