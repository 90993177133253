import {
  AisExportType,
  AisImportType,
  BillingPreference,
  BillingType,
  DoctorSalutation,
  FormCustomizer,
  FormFieldType,
  IdCardType,
  InsuranceCategory,
  OrderFileType,
  PartnerLab,
  Period,
  RequestStatus,
  SpecimenFeature,
  SpecimenGroup,
  SystemStatusType,
  TestTubeColor,
  TestTubeSupplier,
} from '../graphql/generated/graphql';
import {
  FieldTimeOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  SafetyCertificateOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { ComponentType } from 'react';
import { AliasToken } from 'antd/es/theme/interface';

export const translateDoctorSalutation = (doctorSalutation: DoctorSalutation | string): string => {
  switch (doctorSalutation) {
    case DoctorSalutation.FIRMA:
      return 'Firma';
    case DoctorSalutation.FRAU:
      return 'Frau';
    case DoctorSalutation.GRUPPENPRAXIS:
      return 'Gruppenpraxis';
    case DoctorSalutation.HERR:
      return 'Herr';
    default:
      return 'Unbekannte Zuweiseranrede';
  }
};

export interface TestTubeColorProperties {
  translation: string;
  colorCode: string;
}

export const testTubeColorProperties = (color: TestTubeColor | string): TestTubeColorProperties => {
  switch (color) {
    case TestTubeColor.NONE:
      return { translation: 'Keine Farbe', colorCode: '' };
    case TestTubeColor.RED:
      return { translation: 'Rot', colorCode: 'Red' };
    case TestTubeColor.BROWN:
      return { translation: 'Braun', colorCode: 'Peru' };
    case TestTubeColor.GREY:
      return { translation: 'Grau', colorCode: 'Grey' };
    case TestTubeColor.GREEN:
      return { translation: 'Grün', colorCode: 'Green' };
    case TestTubeColor.LIGHTBLUE:
      return { translation: 'Hellblau', colorCode: 'LightSkyBlue' };
    case TestTubeColor.VIOLET:
      return { translation: 'Violett', colorCode: 'BlueViolet' };
    case TestTubeColor.WHITE:
      return { translation: 'Weiß', colorCode: '#efefef' };
    case TestTubeColor.YELLOW:
      return { translation: 'Gelb', colorCode: 'Gold' };
    case TestTubeColor.DARKBLUE:
      return { translation: 'Dunkelblau', colorCode: 'RoyalBlue' };
    case TestTubeColor.DARKBROWN:
      return { translation: 'Dunkelbraun', colorCode: 'SaddleBrown' };
    case TestTubeColor.BLACK:
      return { translation: 'Schwarz', colorCode: 'black' };
    case TestTubeColor.ORANGE:
      return { translation: 'Orange', colorCode: 'DarkOrange' };
    case TestTubeColor.PINK:
      return { translation: 'Rosa', colorCode: 'LightPink' };
    case TestTubeColor.CYAN:
      return { translation: 'Türkis', colorCode: 'Cyan' };
    default:
      return { translation: 'Unbekannte Farbe', colorCode: '' };
  }
};

export const translateTestTubeSupplier = (supplier: TestTubeSupplier | string): string => {
  switch (supplier) {
    case TestTubeSupplier.VACUETTE:
      return 'Vacuette';
    case TestTubeSupplier.SARSTEDT:
      return 'Sarstedt';
    default:
      return 'Unbekannter Lieferant';
  }
};

export const translateSpecimenFeature = (feature: SpecimenFeature | string): string => {
  switch (feature) {
    case SpecimenFeature.FROZEN:
      return 'Gefroren';
    case SpecimenFeature.CENTRIFUGED:
      return 'Zentrifugiert';
    case SpecimenFeature.UV_PROTECTED:
      return 'UV geschützt';
    case SpecimenFeature.COOLED:
      return 'Gekühlt';
    default:
      return 'Unbekannte Materialeigenschaft';
  }
};

export const translateSpecimenGroup = (group: SpecimenGroup | string): string => {
  switch (group) {
    case SpecimenGroup.NONE:
      return 'Keine';
    case SpecimenGroup.SERUM:
      return 'Serum';
    case SpecimenGroup.HARN:
      return 'Harn';
    default:
      return 'Unbekannte Materialgruppe';
  }
};

export const translatePeriod = (period: Period | string): string => {
  switch (period) {
    case Period.CURRENT_REQUEST:
      return 'Aktuelle Anforderung (inkl. Nachforderung)';
    case Period.CURRENT_DAY:
      return 'Aktueller Tag';
    case Period.CURRENT_QUARTER:
      return 'Aktuelles Quartal';
    case Period.CURRENT_YEAR:
      return 'Aktuelles Jahr';
    default:
      return 'Unbekannter Zeitraum';
  }
};

export const translateInsuranceCategory = (category: InsuranceCategory | string): string => {
  switch (category) {
    case InsuranceCategory.ERWERBSTAETIGE:
      return '01 - Erwerbstätige, Arbeitslose, Selbstversicherte, Asylwerber';
    case InsuranceCategory.PENSIONISTEN:
      return '05 - Pensionisten, Ruhegenussempfänger';
    case InsuranceCategory.KRIEGSHINTERBLIEBENE:
      return '07 - Kriegshinterbliebene';
    case InsuranceCategory.OFG:
      return '20 - OFG - Opferfürsorgegesetz';
    case InsuranceCategory.KOVG:
      return '21 - KOVG - Kriegsopferversorgungsgesetz, HVG - Heeresversorgungsgesetz';
    case InsuranceCategory.KOVGD:
      return '22 - KOVG-D (DBVG - Deutsches Bundesversorgungsgesetz)';
    case InsuranceCategory.STVG:
      return '23 - STVG - Strafvollzugsgesetz';
    case InsuranceCategory.VOG:
      return '24 - VOG - Verbrechensopfergesetz';
    case InsuranceCategory.MKP:
      return '25 - MKP - Nichtversicherte';
    case InsuranceCategory.VU:
      return '26 - VU - Nichtversicherte';
    case InsuranceCategory.FREMDSTAATEN:
      return '29 - Fremdstaaten (De-facto-Versicherte) MIT e-card';
    default:
      return 'Unbekannte Versicherungskategorie';
  }
};

export const translateIdCardType = (type: IdCardType | string): string => {
  switch (type) {
    case IdCardType.NICHT_VORHANDEN:
      return 'Nicht vorhanden';
    case IdCardType.FUEHERSCHEIN:
      return 'Führerschein';
    case IdCardType.PERSONALAUSWEIS:
      return 'Personalausweis';
    case IdCardType.REISEPASS:
      return 'Reisepass';
    case IdCardType.BEHINDERTENAUSWEIS:
      return 'Behindertenausweis';
    case IdCardType.STUDENTENAUSWEIS:
      return 'Studentenausweis';
    case IdCardType.SCHUELERAUSWEIS:
      return 'Schülerausweis';
    case IdCardType.WEHRDIENSTAUSWEIS:
      return 'Wehrdienstausweis';
    case IdCardType.SONSTIGER_AUSWEIS:
      return 'Sonstiger Ausweis';
    default:
      return 'Unbekannter Ausweistyp';
  }
};

export const translateBillingType = (type: BillingType | string): string => {
  switch (type) {
    case BillingType.DOCTOR:
      return 'Zuweiser';
    case BillingType.PATIENT:
      return 'Patient';
    case BillingType.INSURANCE:
      return 'Kassa';
    case BillingType.DIAGNOSE:
      return 'Kassa-Diagnose';
    case BillingType.SPECIAL_RATE:
      return 'Sondertarif';
    case BillingType.LG_INSURANCE:
      return 'LG-Kassa';
    case BillingType.LG_DOCTOR:
      return 'LG-Zuweiser';
    case BillingType.LG_DIAGNOSE:
      return 'LG-Kassa-Diagnose';
    default:
      return 'Unbekannter Verrechnungstyp';
  }
};

export const billingTypeChar = (type: BillingType | string): string => {
  switch (type) {
    case BillingType.DOCTOR:
      return 'Z';
    case BillingType.PATIENT:
      return 'P';
    case BillingType.INSURANCE:
      return 'K';
    case BillingType.DIAGNOSE:
      return 'D';
    case BillingType.SPECIAL_RATE:
      return 'S';
    case BillingType.LG_INSURANCE:
      return 'K';
    case BillingType.LG_DOCTOR:
      return 'Z';
    case BillingType.LG_DIAGNOSE:
      return 'D';
    default:
      return '?';
  }
};

export const isLgBillingType = (type: BillingType | string): boolean => {
  return type === BillingType.LG_DIAGNOSE || type === BillingType.LG_DOCTOR || type === BillingType.LG_INSURANCE;
};

export const translateBillingPreference = (billingPreference: BillingPreference | string): string => {
  switch (billingPreference) {
    case BillingPreference.PATIENT:
      return 'Immer an Patient';
    case BillingPreference.DOCTOR:
      return 'Immer an Zuweiser';
    case BillingPreference.ASK:
      return 'Immer fragen';
    default:
      return 'Unbekannte Verrechnungspräferenz';
  }
};

export const translateAisImportType = (type: AisImportType | string): string => {
  switch (type) {
    case AisImportType.GENERIC_ANSI:
      return 'Standard ANSI';
    case AisImportType.GENERIC_UTF8:
      return 'Standard UTF8';
    case AisImportType.BHZ_KRAUS_LABENE:
      return 'BHZ Kraus Labene';
    default:
      return 'Unbekannter AIS-Import-Typ';
  }
};

export const translateAisExportType = (type: AisExportType | string): string => {
  switch (type) {
    case AisExportType.CSV:
      return 'CSV';
    case AisExportType.PDF:
      return 'PDF';
    default:
      return 'Unbekannter AIS-Export-Typ';
  }
};

interface RequestStatusMetadata {
  translation: string;
  color: string;
  cssColor: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iconType: ComponentType<any>;
}

export const findRequestStatusMetadata = (status: RequestStatus | string, token: AliasToken): RequestStatusMetadata => {
  switch (status) {
    case RequestStatus.NEW:
      return {
        translation: 'Bereit zum Drucken',
        color: 'orange',
        cssColor: token.colorWarning,
        iconType: PrinterOutlined,
      };
    case RequestStatus.PRINTED:
      return {
        translation: 'Bereit zum Senden',
        color: 'blue',
        cssColor: token.colorInfo,
        iconType: SendOutlined,
      };
    case RequestStatus.SENT:
      return { translation: 'Gesendet', color: '', cssColor: '', iconType: FieldTimeOutlined };
    case RequestStatus.RECEIVED:
      return {
        translation: 'Empfangen',
        color: 'green',
        cssColor: token.colorSuccess,
        iconType: SafetyCertificateOutlined,
      };
    default:
      return { translation: 'Unbekannt', color: '', cssColor: '', iconType: QuestionCircleOutlined };
  }
};

export const translateOrderFileType = (type: OrderFileType | string): string => {
  switch (type) {
    case OrderFileType.HL7:
      return 'HL7';
    case OrderFileType.TRANSFER_DOCUMENT:
      return 'Überweisungsschein';
    case OrderFileType.FORM_DOCUMENT:
      return 'Dokument';
    default:
      return 'Unbekannter Auftragsdateityp';
  }
};

export const translateFormFieldType = (type: FormFieldType | string): string => {
  switch (type) {
    case FormFieldType.DATE:
      return 'Datepicker';
    case FormFieldType.CHECKBOX_GROUP:
      return 'Checkbox-Gruppe';
    case FormFieldType.OPTION_GROUP:
      return 'Option-Gruppe';
    case FormFieldType.SELECT:
      return 'Select';
    case FormFieldType.INPUT:
      return 'Textfeld';
    default:
      return 'Unbekannter Formular-Feld-Typ';
  }
};

export const translatePartnerLab = (type: PartnerLab | string): string => {
  switch (type) {
    case PartnerLab.NONE:
      return 'Keines';
    case PartnerLab.PL1:
      return 'PL 1';
    case PartnerLab.PL2:
      return 'PL 2';
    case PartnerLab.PL3:
      return 'PL 3';
    default:
      return 'Unbekannter Partnerlabor-Typ';
  }
};

export const translateFormCustomizer = (type: FormCustomizer | string): string => {
  switch (type) {
    case FormCustomizer.NONE:
      return 'Keiner';
    case FormCustomizer.ENML_RENNER_SCHEIN:
      return 'ENML Renner Schein';
    default:
      return 'Unbekannter Customizer';
  }
};

export const translateSystemStatusType = (type: SystemStatusType | string): string => {
  switch (type) {
    case SystemStatusType.INFO:
      return 'Info';
    case SystemStatusType.WARNING:
      return 'Warnung';
    case SystemStatusType.ERROR:
      return 'Fehler';
    default:
      return 'Unbekannter Systemstatus Typ';
  }
};

export const billingTypePriority = (type: BillingType | string): number => {
  switch (type) {
    case BillingType.DOCTOR:
      return 8;
    case BillingType.PATIENT:
      return 7;
    case BillingType.INSURANCE:
      return 5;
    case BillingType.DIAGNOSE:
      return 4;
    case BillingType.SPECIAL_RATE:
      return 3;
    case BillingType.LG_INSURANCE:
      return 2;
    case BillingType.LG_DOCTOR:
      return 6;
    case BillingType.LG_DIAGNOSE:
      return 1;
    default:
      return -1;
  }
};
