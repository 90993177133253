import React from 'react';
import { App, Checkbox, Form as AntForm, Input, Modal, Select, Space, Tooltip } from 'antd';
import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useForm } from 'antd/es/form/Form';
import { translateFormCustomizer } from '../../../../utils/enumHelpers.ts';
import { graphql } from '../../../../graphql/generated';
import { FormCustomizer, FormInput } from '../../../../graphql/generated/graphql.ts';

const { Option } = Select;

const CREATE_FORM_MUTATION = graphql(`
  mutation CreateForm($input: FormInput!) {
    createForm(input: $input) {
      id
    }
  }
`);

export const CreateFormModal: React.FC<{
  labId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ labId, open, onClose, onSuccess }) => {
  const [createFormMutation, { loading }] = useMutation(CREATE_FORM_MUTATION);
  const [form] = useForm<FormInput>();
  const { message } = App.useApp();

  const create = async (values: FormInput) => {
    try {
      await createFormMutation({
        variables: {
          input: {
            ...values,
            labId: labId,
            fields: [],
          },
        },
      });

      message.success('Formular wurde erstellt');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Formular konnte nicht erstellt werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={600}
      title="Neues Formular erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={open}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <AntForm<FormInput>
        form={form}
        layout="vertical"
        name="create-form"
        initialValues={{
          labId: labId,
          name: '',
          header: '',
          description: '',
          pickup: false,
          printRequired: false,
          printBeforeSend: false,
          direct: false,
          customizer: FormCustomizer.NONE,
          restore: true,
        }}
        onFinish={values => create(values)}
      >
        <AntForm.Item name="name" label="Bezeichnung" rules={[{ required: true, whitespace: true }]}>
          <Input />
        </AntForm.Item>
        <AntForm.Item name="header" label="Überschrift">
          <Input />
        </AntForm.Item>
        <AntForm.Item name="description" label="Beschreibung">
          <Input.TextArea rows={5} />
        </AntForm.Item>
        <AntForm.Item name="customizer" label="Customizer" rules={[{ required: true }]}>
          <Select>
            {Object.keys(FormCustomizer).map(fc => {
              return (
                <Option key={fc} value={fc}>
                  {translateFormCustomizer(fc)}
                </Option>
              );
            })}
          </Select>
        </AntForm.Item>
        <Space direction="vertical">
          <AntForm.Item name="printRequired" valuePropName="checked" noStyle>
            <Checkbox>Dokument drucken</Checkbox>
          </AntForm.Item>
          <AntForm.Item name="printBeforeSend" valuePropName="checked" noStyle>
            <Checkbox>Dokument vor dem Senden drucken</Checkbox>
          </AntForm.Item>
          <AntForm.Item name="pickup" valuePropName="checked" noStyle>
            <Checkbox>Dokument elektronisch übertragen</Checkbox>
          </AntForm.Item>
          <AntForm.Item name="direct" valuePropName="checked" noStyle>
            <Checkbox>
              Direkt{' '}
              <Tooltip title="Ohne Anforderung mit Patienten- und Formulardaten erstellbar">
                <InfoCircleOutlined />
              </Tooltip>
            </Checkbox>
          </AntForm.Item>
          <AntForm.Item name="restore" valuePropName="checked" noStyle>
            <Checkbox>
              Wiederherstellen{' '}
              <Tooltip title="Beim Wiederholen einer Anforderung die Formularfelder wiederherstellen">
                <InfoCircleOutlined />
              </Tooltip>
            </Checkbox>
          </AntForm.Item>
        </Space>
      </AntForm>
    </Modal>
  );
};
