import React, { useEffect, useState } from 'react';
import { UserMenu } from '../../components/UserMenu';
import { hasAnyRelevantRole, hasSomeRole, realmRoles, Role, translateRole, userFullname } from '../../utils/user';
import { useQuery } from '@apollo/client';
import { AssignCurrentDoctorModal } from './AssignCurrentDoctorModal';
import { useNavigate } from 'react-router-dom';
import { Md5 } from 'ts-md5';
import { graphql } from '../../graphql/generated';
import { useAuth } from 'react-oidc-context';
import { oidcConfig } from '../../oidcConfig.ts';
import { useSocket } from '../../socket/useSocket.ts';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';

const USER_MENU_QUERY = graphql(`
  query UserMenu($doctorId: ID!) {
    doctor(id: $doctorId) {
      id
      name
      disabled
    }

    assignmentForCurrentUser {
      id
      primaryDoctor {
        id
      }
      additionalDoctors {
        id
      }
    }
  }
`);

export const UserMenuWrapper: React.FC = () => {
  const navigate = useNavigate();
  const [pictureUrl, setPrictureUrl] = useState<string>('');
  const { socket } = useSocket();

  const [assignDoctorOpen, setAssignDoctorOpen] = useState(false);

  const auth = useAuth();
  const { currentDoctorId, setCurrentDoctorId } = useCurrentContextStore();

  const roleNames =
    realmRoles(auth.user)
      .filter(it => Object.keys(Role).includes(it))
      .map(it => translateRole(it))
      .join(', ') ?? '';
  const hasAnyRole = hasAnyRelevantRole(auth.user);

  useEffect(() => {
    if (auth.user?.profile.email) {
      const emailHash = Md5.hashStr(auth.user.profile.email);
      setPrictureUrl(`https://s.gravatar.com/avatar/${emailHash}?d=mp&r=pg&s=64`);
    }
  }, [auth]);

  useEffect(() => {
    socket.on('sso-logout', event => {
      if (auth.user?.session_state === event.sessionId) {
        auth.signoutRedirect(); // redirects to kc logout page
      }
    });
    return () => {
      socket.off('sso-logout');
    };
  }, [socket, auth]);

  const updateCurrentDoctorId = (doctorId: string | undefined | null) => {
    if (!doctorId) {
      return;
    }

    setCurrentDoctorId(doctorId);
    setAssignDoctorOpen(false);
    navigate('/');
  };

  const { data, loading } = useQuery(USER_MENU_QUERY, {
    variables: {
      doctorId: currentDoctorId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !currentDoctorId || !hasAnyRole,
  });

  const userFullName = userFullname(auth.user, auth.user?.profile.preferred_username);
  const doctorName = data?.doctor ? data.doctor.name : loading ? '' : 'Kein Zuweiser festgelegt';

  useEffect(() => {
    if (data?.doctor && currentDoctorId && doctorName && auth.user?.profile.sub && userFullName) {
      window.nativeApi?.setUserInfo({
        doctorId: currentDoctorId,
        doctorName: doctorName,
        userId: auth.user.profile.sub,
        userName: userFullName,
      });
    } else {
      window.nativeApi?.setUserInfo(null);
    }
  }, [data, currentDoctorId, doctorName, auth, userFullName]);

  return (
    <>
      <UserMenu
        roleName={roleNames}
        username={auth.user?.profile.preferred_username}
        disabled={data?.doctor?.disabled ?? false}
        pictureUrl={pictureUrl}
        doctorName={doctorName}
        userFullName={userFullName}
        showDoctorSelect={
          hasAnyRole &&
          ((data?.assignmentForCurrentUser?.additionalDoctors?.length ?? 0) > 0 ||
            hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_LAB_STAFF], auth.user))
        }
        showDoctorReset={
          hasAnyRole && !!currentDoctorId && data?.assignmentForCurrentUser?.primaryDoctor.id !== currentDoctorId
        }
        onDoctorSelect={() => setAssignDoctorOpen(true)}
        onDoctorReset={() => updateCurrentDoctorId(data?.assignmentForCurrentUser?.primaryDoctor.id)}
        onAccountOpen={() =>
          (window.location.href =
            oidcConfig.authority +
            `/account?referrer=${oidcConfig.client_id}&referrer_uri=${encodeURIComponent(window.location.href)}`)
        }
        onLogout={() => {
          const sessionId = auth.user?.session_state;
          if (sessionId) {
            socket.emit('sso-logout', sessionId);
          }
        }}
      />
      <AssignCurrentDoctorModal
        currentDoctorId={currentDoctorId}
        open={assignDoctorOpen}
        onSuccess={updateCurrentDoctorId}
        onClose={() => setAssignDoctorOpen(false)}
      />
    </>
  );
};
