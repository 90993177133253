import React, { DragEvent, useState } from 'react';
import { App, Modal, Space, Table, Upload } from 'antd';
import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../../../hooks/store/useCurrentContextStore.ts';

interface ProfileImportResponse {
  counter: number;
  unknownParameters: UnknownParameter[];
}

interface UnknownParameter {
  profileName: string;
  paramShortName: string;
}

const { Dragger } = Upload;

const allowedMimeTypes = ['application/json'];

export const ImportProfileModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { currentDoctorId } = useCurrentContextStore();
  const [uploading, setUploading] = useState(false);
  const auth = useAuth();
  const { message, modal } = App.useApp();

  const onDrop = (event: DragEvent) => {
    if (Array.from(event.dataTransfer.files).some(f => !allowedMimeTypes.includes(f.type))) {
      message.error('Nur JSON-Dateien sind erlaubt');
    }
  };

  const beforeUpload = (file: File) => {
    const mimeTypeOk = allowedMimeTypes.find(mimeType => mimeType === file.type);
    if (!mimeTypeOk) {
      message.error('Nur JSON-Dateien sind erlaubt');
    }
    const fileSizeOk = file.size / 1024 / 1024 < 0.5;
    if (!fileSizeOk) {
      message.error('Datei muss kleiner als 0,5 MB sein');
    }
    if (mimeTypeOk && fileSizeOk) {
      upload(file);
    }
    return false;
  };

  const upload = async (file: File) => {
    setUploading(true);
    const endpoint = window._env_.API_URL + '/rest/profile-import';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('doctorId', currentDoctorId);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: { authorization: `Bearer ${auth.user?.access_token}` },
      });
      const body: ProfileImportResponse = await response.json();
      if (response.ok) {
        modal.info({
          width: 600,
          title: 'Profilimport',
          content: (
            <Space
              direction="vertical"
              className={css`
                width: 100%;
              `}
            >
              <div>
                <strong>{body.counter}</strong> {body.counter === 1 ? 'Profil wurde' : 'Profile wurden'} erfolgreich
                importiert
              </div>
              {!!body.unknownParameters.length && (
                <>
                  <h4>
                    Folgende Parameter wurden beim Import ignoriert, da sie vom System nicht identifiziert werden
                    können:
                  </h4>
                  <Table<UnknownParameter>
                    size="small"
                    dataSource={body.unknownParameters}
                    rowKey={() => uuidv4()}
                    pagination={false}
                    scroll={{ y: '300px' }}
                    columns={[
                      {
                        title: 'Profil',
                        dataIndex: 'profileName',
                        key: 'profileName',
                      },
                      {
                        title: 'Parameter',
                        dataIndex: 'paramShortName',
                        key: 'paramShortName',
                      },
                    ]}
                  />
                </>
              )}
            </Space>
          ),
        });
      } else {
        message.error('Import fehlgeschlagen: ' + body);
      }
    } catch (e) {
      message.error('Import fehlgeschlagen: ' + e);
    } finally {
      setUploading(false);
      onClose();
    }
  };

  return (
    <Modal width={768} title="Profile importieren" open={open} onCancel={onClose} okButtonProps={{ hidden: true }}>
      <Dragger showUploadList={false} beforeUpload={beforeUpload} accept=".json">
        <div
          onDrop={onDrop}
          style={{
            display: 'flex',
            height: '150px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p className="ant-upload-drag-icon">{uploading ? <LoadingOutlined /> : <CloudUploadOutlined />}</p>
          <p className="ant-upload-text">Profile importieren</p>
          <p className="ant-upload-hint">
            Klicken Sie hier, oder ziehen Sie die Profile-Export-Datei in diesen Bereich, um den Vorgang zu starten.
            Erlaubter Dateityp: JSON
          </p>
        </div>
      </Dragger>
    </Modal>
  );
};
