import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Flex, Input, Popover, Table, theme, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import {
  CheckOutlined,
  EditOutlined,
  ExportOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { Markable } from '../../../components/Markable';
import { openExternalLink } from '../../../utils/linkOpener';
import { humanizedHours } from '../../../utils/dateFormatUtils';
import { tableActionCell } from '../../../styles/globalCss';
import { currency } from '../../../utils/currency';
import { translatePartnerLab } from '../../../utils/enumHelpers';
import { TableList } from '../../../components/TableList.tsx';
import { useTableHeight } from '../../../hooks/useTableHeight';
import { css } from '@emotion/css';
import { ellipsis } from '../../../utils/ellipsis';
import { graphql } from '../../../graphql/generated';
import { ParametersQuery, PartnerLab } from '../../../graphql/generated/graphql.ts';
import { CreateParameterModal } from './parameters/CreateParameterModal.tsx';
import { UpdateParameterModal } from './parameters/UpdateParameterModal.tsx';

const { useToken } = theme;

const PARAMETERS_QUERY = graphql(`
  query Parameters($labId: ID!) {
    labParameters(labId: $labId) {
      id
      longName
      shortName
      specimens {
        id
        name
        classification
      }
      withParameters {
        id
        shortName
      }
      withoutParameters {
        id
        shortName
      }
      specialRates {
        id
        shortName
      }
      forms {
        id
        name
      }
      deactivated
      doctorBilling
      patientBilling
      description
      edifactNumber
      link
      lgDoctorBilling
      storagePeriod
      priceDoctor
      priceLgDoctor
      pricePatient
      partnerLab
      synonyms
      acute
      volume
    }
  }
`);

type Parameter = NonNullable<ParametersQuery['labParameters']>[number];

export const Parameters: React.FC = () => {
  const { token } = useToken();
  const { id: labId } = useParams<{ id: string }>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredParameters, setFilteredParameters] = useState<Parameter[]>([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);

  const contentRef = useRef<HTMLDivElement>(null);
  const tableHeight = useTableHeight(contentRef);

  const { data, loading, refetch } = useQuery(PARAMETERS_QUERY, {
    variables: {
      labId: labId ?? '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  const debouncedSearch = useDebouncedCallback((value: string) => {
    setSearchValue(value);
    setFilteredParameters(
      data?.labParameters?.filter(p => {
        if (value.length) {
          return value
            .toLowerCase()
            .split(' ')
            .some(
              token =>
                p.shortName.toLowerCase().includes(token) ||
                p.longName.toLowerCase().includes(token) ||
                (p.edifactNumber?.toString().includes(token) ?? false) ||
                p.synonyms.some(it => it.toLowerCase().includes(token))
            );
        }
        return true;
      }) ?? []
    );
  }, 300);

  useEffect(() => {
    if (data) {
      debouncedSearch(searchValue);
    }
  }, [data, searchValue, debouncedSearch]);

  const columns: ColumnsType<Parameter> = [
    {
      title: 'Kurzbez.',
      dataIndex: 'shortName',
      key: 'shortName',
      defaultSortOrder: 'ascend',
      width: 130,
      sorter: (a, b) => a.shortName.localeCompare(b.shortName),
      render: value => <Markable tokens={searchValue ?? ''}>{value}</Markable>,
    },
    {
      title: 'Langbez.',
      dataIndex: 'longName',
      key: 'longName',
      width: 130,
      ellipsis: true,
      sorter: (a, b) => a.longName.localeCompare(b.longName),
      render: value => <Markable tokens={searchValue ?? ''}>{ellipsis(value, 25)}</Markable>,
    },
    {
      title: 'Edifact-Nr.',
      dataIndex: 'edifactNumber',
      key: 'edifactNumber',
      width: 120,
      sorter: (a, b) => (a.edifactNumber ?? 0) - (b.edifactNumber ?? 0),
      render: value => <Markable tokens={searchValue ?? ''}>{value}</Markable>,
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      key: 'description',
      width: 120,
      render: value => {
        if (!value) {
          return;
        }
        return (
          <Popover
            trigger={['click']}
            content={<div style={{ whiteSpace: 'pre-wrap', maxWidth: '500px' }}>{value}</div>}
          >
            <Button
              type="link"
              className={css`
                height: 20px;
                margin: 0;
                padding: 0;
              `}
              icon={<EyeOutlined />}
            />
          </Popover>
        );
      },
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      width: 80,
      render: value => {
        if (!value) {
          return;
        }
        return (
          <Button
            type="link"
            className={css`
              height: 20px;
              margin: 0;
              padding: 0;
            `}
            icon={<ExportOutlined />}
            onClick={() => openExternalLink(value)}
          />
        );
      },
    },
    {
      title: 'Material',
      dataIndex: 'specimens',
      key: 'specimens',
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <TableList entries={record.specimens.map(it => `${it.name} - ${it.classification}`)} maxEntries={3} />
      ),
    },
    {
      title: 'Lagerdauer (Std.)',
      dataIndex: 'storagePeriod',
      key: 'storagePeriod',
      width: 160,
      sorter: (a, b) => (a.storagePeriod === b.storagePeriod ? 0 : a.storagePeriod > b.storagePeriod ? 1 : -1),
      render: value => (Math.sign(value) === -1 ? '' : <Tooltip title={humanizedHours(value)}>{value}</Tooltip>),
    },
    {
      title: 'Privat an Zuw.',
      dataIndex: 'doctorBilling',
      key: 'doctorBilling',
      width: 130,
      sorter: (a, b) => Number(a.doctorBilling) - Number(b.doctorBilling),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Privat an Patient',
      dataIndex: 'patientBilling',
      key: 'patientBilling',
      width: 160,
      sorter: (a, b) => Number(a.patientBilling) - Number(b.patientBilling),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: 'LG - Privat Zuw.',
      dataIndex: 'lgDoctorBilling',
      key: 'lgDoctorBilling',
      width: 150,
      sorter: (a, b) => Number(a.lgDoctorBilling) - Number(b.lgDoctorBilling),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Aktiv',
      dataIndex: 'deactivated',
      key: 'deactivated',
      width: 80,
      sorter: (a, b) => Number(b.deactivated) - Number(a.deactivated),
      render: value => (!value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Nur mit',
      dataIndex: 'withParameters',
      key: 'withParameters',
      width: 80,
      ellipsis: true,
      render: (_, record) => <TableList entries={record.withParameters.map(it => it.shortName)} maxEntries={3} />,
    },
    {
      title: 'Nicht mit',
      dataIndex: 'withoutParameters',
      key: 'withoutParameters',
      width: 80,
      ellipsis: true,
      render: (_, record) => <TableList entries={record.withoutParameters.map(it => it.shortName)} maxEntries={3} />,
    },
    {
      title: 'Sondertarife',
      dataIndex: 'specialRates',
      key: 'specialRates',
      width: 110,
      ellipsis: true,
      render: (_, record) => <TableList entries={record.specialRates.map(it => it.shortName)} maxEntries={3} />,
    },
    {
      title: 'Formulare',
      dataIndex: 'forms',
      key: 'forms',
      width: 110,
      ellipsis: true,
      render: (_, record) => <TableList entries={record.forms.map(it => it.name)} maxEntries={3} />,
    },
    {
      title: 'Preis Zuw.',
      dataIndex: 'priceDoctor',
      key: 'priceDoctor',
      width: 110,
      sorter: (a, b) => (a.priceDoctor ?? -1) - (b.priceDoctor ?? -1),
      render: value => currency(value),
    },
    {
      title: 'Preis Patient',
      dataIndex: 'pricePatient',
      key: 'pricePatient',
      width: 120,
      sorter: (a, b) => (a.pricePatient ?? -1) - (b.pricePatient ?? -1),
      render: value => currency(value),
    },
    {
      title: 'Preis LG-Zuw.',
      dataIndex: 'priceLgDoctor',
      key: 'priceLgDoctor',
      width: 140,
      sorter: (a, b) => (a.priceLgDoctor ?? -1) - (b.priceLgDoctor ?? -1),
      render: value => currency(value),
    },
    {
      title: 'Partnerlabor',
      dataIndex: 'partnerLab',
      key: 'partnerLab',
      width: 130,
      sorter: (a, b) => a.partnerLab.localeCompare(b.partnerLab),
      render: value => (value === PartnerLab.NONE ? '' : translatePartnerLab(value)),
    },
    {
      title: 'Synonyme',
      dataIndex: 'synonyms',
      key: 'synonyms',
      width: 110,
      ellipsis: true,
      render: value => <TableList entries={value} maxEntries={3} />,
    },
    {
      title: 'Akut',
      dataIndex: 'acute',
      key: 'acute',
      width: 70,
      sorter: (a, b) => Number(a.acute) - Number(b.acute),
      render: value => (value ? <CheckOutlined /> : ''),
    },
    {
      title: 'Volumen',
      dataIndex: 'volume',
      key: 'volume',
      width: 100,
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => <Button icon={<EditOutlined />} type="text" onClick={() => setEditId(record.id)} />,
    },
  ];

  return (
    <Flex
      vertical
      className={css`
        height: 100%;
        padding: ${token.paddingLG}px;
      `}
    >
      <Flex
        justify="flex-end"
        className={css`
          margin-bottom: ${token.paddingSM}px;
        `}
      >
        <Input
          allowClear
          placeholder="Suche"
          onChange={e => debouncedSearch(e.target.value)}
          prefix={<SearchOutlined />}
          autoFocus
          suffix={
            <Tooltip title="Suche nach Kurzbezeichnung, Langbezeichnung, Edifact-Nummer oder Synonyme">
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ width: '300px', marginRight: token.paddingSM }}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          disabled={loading}
          onClick={() => setCreateModalOpen(true)}
        >
          Neuer Parameter
        </Button>
      </Flex>
      <div
        ref={contentRef}
        className={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <Table<Parameter>
          scroll={{ x: 'max-content', y: tableHeight }}
          rowKey={record => record.id}
          size="middle"
          showSorterTooltip={false}
          dataSource={filteredParameters}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Parametern`,
            pageSizeOptions: ['50', '100', '500', '1000'],
            defaultPageSize: 50,
          }}
          loading={loading}
          columns={columns}
        />
      </div>
      <CreateParameterModal
        open={createModalOpen}
        onSuccess={() => refetch()}
        onClose={() => setCreateModalOpen(false)}
        labId={labId ?? ''}
        parameters={data?.labParameters ?? []}
      />
      <UpdateParameterModal
        parameterId={editId}
        parameters={data?.labParameters ?? []}
        labId={labId ?? ''}
        onSuccess={() => refetch()}
        onClose={() => setEditId(null)}
      />
    </Flex>
  );
};
