import React, { useState } from 'react';
import { Alert, App, Button, DatePicker, Dropdown, Empty, Input, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { MainContent } from '../components/MainContent';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { Markable } from '../components/Markable';
import {
  EllipsisOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  PrinterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { API_DATE_FORMAT, SHORT_DATE_FORMAT, SHORT_DATETIME_FORMAT } from '../utils/dateFormatUtils';
import { useDebouncedCallback } from 'use-debounce';
import { PageHeader } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { tableActionCell, tableHoverPointer } from '../styles/globalCss';
import { patientAddress } from '../utils/patient';
import { useNavigate } from 'react-router-dom';
import { usePrintEmptyLabel } from '../hooks/usePrintEmptyLabel';
import { useAppStore } from '../hooks/store/useAppStore';
import { ItemType } from 'antd/es/menu/interface';
import { Forms } from './forms/Forms.tsx';
import { hasSomeRole, Role } from '../utils/user';
import { graphql } from '../graphql/generated';
import { PatientData, PatientSearchFilter, PatientSearchQuery } from '../graphql/generated/graphql.ts';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';

export const PATIENT_SEARCH_QUERY = graphql(`
  query PatientSearch($filter: PatientSearchFilter!) {
    patientSearch(filter: $filter) {
      exact
      entries {
        id
        insuranceShortName
        createdAt
        patientData {
          birthday
          city
          country
          firstName
          lastName
          zip
          svnr
          title
          street
          email
          gender
          phone
          externalId
          insuranceCode
          insuranceCategory
          idCardType
          idCardNumber
          idCardAuthority
          insuredPerson {
            title
            firstName
            lastName
            birthday
            gender
            svnr
            city
            zip
            street
            country
            phone
            email
          }
        }
      }
    }
  }
`);

export type Request = NonNullable<PatientSearchQuery['patientSearch']>['entries'][number];

export const PatientSearch: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { currentDoctorId } = useCurrentContextStore();
  const setPatientData = useAppStore(state => state.setPatientData);
  const [filter, setFilter] = useState<PatientSearchFilter>({
    search: '',
    birthday: null,
  });
  const printEmptyLabel = usePrintEmptyLabel();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebouncedCallback(value => setFilter({ ...filter, search: value }), 300);
  const anyFilterActive = filter.search.length >= 3 || filter.birthday;
  const { message } = App.useApp();
  const [formsPatientData, setFormsPatientData] = useState<PatientData | null>(null);

  const newRequestAllowed = hasSomeRole([Role.ROLE_LR_MEDCOM, Role.ROLE_LR_USER, Role.ROLE_LR_LAB_ADMIN], auth.user);

  const { data, loading, error } = useQuery(PATIENT_SEARCH_QUERY, {
    variables: {
      filter: filter,
    },
    fetchPolicy: 'cache-and-network',
    skip: !anyFilterActive,
  });

  const columns: ColumnsType<Request> = [
    {
      title: 'Name',
      dataIndex: ['patientData', 'lastName'],
      key: 'patientData.lastName',
      sorter: (a, b) => a.patientData.lastName.localeCompare(b.patientData.lastName),
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <Typography.Text style={{ fontWeight: 500 }}>
          {record.patientData.title}{' '}
          <Markable tokens={filter.search ?? ''}>
            {record.patientData.firstName} {record.patientData.lastName}
          </Markable>
        </Typography.Text>
      ),
    },
    {
      title: 'SVNR',
      dataIndex: ['patientData', 'svnr'],
      key: 'patientData.svnr',
      sorter: (a, b) => a.patientData.svnr.localeCompare(b.patientData.svnr),
      width: 70,
      ellipsis: true,
      render: value => <Markable tokens={filter.search ?? ''}>{value}</Markable>,
    },
    {
      title: 'Geburtstag',
      dataIndex: ['patientData', 'birthday'],
      key: 'patientData.birthday',
      sorter: (a, b) => a.patientData.birthday.localeCompare(b.patientData.birthday),
      width: 120,
      ellipsis: true,
      render: value => dayjs(value).format(SHORT_DATE_FORMAT),
    },
    {
      title: 'Kasse',
      dataIndex: 'insuranceShortName',
      key: 'insuranceShortName',
      sorter: (a, b) => a.insuranceShortName.localeCompare(b.insuranceShortName),
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Adresse',
      dataIndex: ['patientData', 'zip'],
      key: 'patientData.zip',
      sorter: (a, b) => patientAddress(a.patientData).localeCompare(patientAddress(b.patientData)),
      width: 100,
      ellipsis: true,
      render: (_, record) => patientAddress(record.patientData),
    },
    {
      title: 'Stand vom',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: value => dayjs(value).format(SHORT_DATETIME_FORMAT),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '100px',
      className: tableActionCell,
      render: (_, record) => {
        const items: ItemType[] = [];
        if (window.nativeApi) {
          items.push({
            label: 'Leer-Etikett drucken',
            key: 'empty-label',
            icon: <PrinterOutlined />,
            onClick: info => {
              info.domEvent.stopPropagation();
              printEmptyLabel(
                {
                  doctorId: currentDoctorId,
                  patientTitle: record.patientData.title,
                  patientLastName: record.patientData.lastName,
                  patientFirstName: record.patientData.firstName,
                  patientSvnr: record.patientData.svnr,
                },
                1,
                () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
                () => message.success('Leer-Etikett wurde an den Drucker gesendet')
              );
            },
          });
        }
        items.push({
          label: 'Formulare',
          key: 'forms',
          icon: <FileTextOutlined />,
          onClick: info => {
            info.domEvent.stopPropagation();
            setFormsPatientData(record.patientData);
          },
        });

        return (
          <Dropdown menu={{ items: items }} trigger={['click']} placement="bottomRight">
            <Button
              icon={<EllipsisOutlined style={{ fontSize: '20px' }} />}
              type="text"
              onClick={e => e.stopPropagation()}
            />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <MainContent>
      <Space direction="vertical" style={{ width: '100%' }}>
        <PageHeader
          title="Patientensuche"
          style={{ padding: 0, paddingBottom: 'inherit' }}
          extra={
            <Space key="searchbar" direction="horizontal">
              <Input
                autoFocus
                allowClear
                placeholder="Suche - mind. 3 Zeichen eingeben"
                value={searchValue ?? ''}
                onChange={e => {
                  setSearchValue(e.target.value);
                  debouncedSearch(e.target.value);
                }}
                prefix={<SearchOutlined />}
                suffix={
                  <Tooltip title="Suche nach Vorname, Nachname oder SVNR">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                style={{ width: '350px' }}
              />
              <DatePicker
                format={SHORT_DATE_FORMAT}
                placeholder="Geburtstag"
                allowClear
                onChange={date =>
                  setFilter({
                    ...filter,
                    birthday: date ? date.format(API_DATE_FORMAT) : null,
                  })
                }
                disabledDate={current =>
                  current && (current.isAfter(dayjs()) || current.isBefore(dayjs().subtract(120, 'years')))
                }
              />
            </Space>
          }
        />
        {error && <Alert showIcon type="error" message="Es ist ein Fehler bei der Suchabfrage aufgetreten" />}
        {data?.patientSearch?.exact === false && (
          <Alert
            showIcon
            type="info"
            message="Das Suchergebnis beinhaltet nicht alle möglichen Patienten. Bitte schränken Sie die Suche weiter ein (z.B. mit Angabe der SVNR), um exakte Ergbnisse zu erhalten."
          />
        )}
        <Table<Request>
          scroll={{ x: 'max-content' }}
          sticky
          showSorterTooltip={false}
          rowKey={record => record.id}
          size="middle"
          dataSource={data?.patientSearch?.entries ?? []}
          pagination={false}
          loading={loading}
          columns={columns}
          onRow={record => ({
            onClick: () => {
              if (!newRequestAllowed) {
                return;
              }
              setPatientData(record.patientData);
              navigate('/anforderung/patient-erfassung');
            },
          })}
          rowClassName={newRequestAllowed ? tableHoverPointer : undefined}
          locale={{
            emptyText: (
              <Empty
                description={anyFilterActive ? 'Keine Patienten gefunden' : 'Bitte geben Sie Suchkriterien an'}
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            ),
          }}
        />
      </Space>
      <Modal
        title={`Formulare für ${formsPatientData?.firstName} ${formsPatientData?.lastName}`}
        width={768}
        open={!!formsPatientData}
        footer={null}
        onCancel={() => setFormsPatientData(null)}
        destroyOnClose
      >
        {formsPatientData && <Forms patientData={formsPatientData} doctorId={currentDoctorId} />}
      </Modal>
    </MainContent>
  );
};
