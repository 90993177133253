import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export interface CurrentContextState {
  userId: string;
  setUserId: (userId: string) => void;

  currentDoctorId: string;
  setCurrentDoctorId: (doctorId: string) => void;
}

export const useCurrentContextStore = create<CurrentContextState>()(
  persist(
    devtools(set => ({
      userId: '',
      setUserId: userId => set({ userId }),

      currentDoctorId: '',
      setCurrentDoctorId: currentDoctorId => set({ currentDoctorId }),
    })),
    { name: 'current-context-store', storage: createJSONStorage(() => sessionStorage) }
  )
);
