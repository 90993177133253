import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useAppStore } from '../../../hooks/store/useAppStore';
import { ColumnsType } from 'antd/es/table';
import { App, Button, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { SHORT_DATETIME_FORMAT } from '../../../utils/dateFormatUtils';
import { tableActionCell, tableHoverPointer } from '../../../styles/globalCss';
import { RequestDetailsModal } from '../RequestDetailsModal';
import { css } from '@emotion/css';
import { EditOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useShallow } from 'zustand/react/shallow';
import { showParamWarning } from '../../../components/showParamWarning';
import { stripTypename } from '../../../utils/helpers';
import { TableList } from '../../../components/TableList.tsx';
import {
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
  PatientData,
  PatientRequestsQuery,
  RequestStatus,
} from '../../../graphql/generated/graphql.ts';
import { graphql } from '../../../graphql/generated';
import { useCurrentContextStore } from '../../../hooks/store/useCurrentContextStore.ts';
import { RequestStatusBadge } from '../../../components/RequestStatusBadge.tsx';

const PATIENT_REQUESTS_QUERY = graphql(`
  query PatientRequests($doctorId: ID!, $svnr: String!) {
    patientRequests(doctorId: $doctorId, svnr: $svnr) {
      id
      createdAt
      reorderable
      reorderableUntil
      status
      orders {
        id
        lab {
          id
        }
        orderParameters {
          id
          shortName
          longName
          billingType
        }
      }
      patientData {
        birthday
        city
        country
        email
        externalId
        firstName
        lastName
        gender
        zip
        svnr
        title
        street
        phone
        insuranceCode
        idCardAuthority
        idCardNumber
        idCardType
        insuranceCategory
        insuredPerson {
          phone
          street
          title
          svnr
          zip
          birthday
          gender
          firstName
          email
          country
          city
          lastName
        }
      }
    }
  }
`);

type PatientRequests = NonNullable<PatientRequestsQuery['patientRequests']>[number];

export const Requests: React.FC<{
  params: readonly ParameterSelection_RequestableParameterFragment[];
  onAdd: (inputs: ParameterToAddInput[]) => void;
  onUpdate: (requestId: string) => void;
  onReorder: (requestId: string, patientData: PatientData) => void;
  processing: boolean;
  reordersOnly: boolean;
  flipParamNames: boolean;
}> = ({ params, onAdd, onReorder, onUpdate, processing, reordersOnly, flipParamNames }) => {
  const { notification } = App.useApp();
  const { patientData, requestIdForReorder, requestIdForUpdate } = useAppStore(
    useShallow(state => ({
      patientData: state.patientData,
      requestIdForReorder: state.requestIdForReorder,
      requestIdForUpdate: state.requestIdForUpdate,
    }))
  );
  const { currentDoctorId } = useCurrentContextStore();

  const [detailsRequestId, setDetailsRequestId] = useState<string | null>(null);

  const { data, loading } = useQuery(PATIENT_REQUESTS_QUERY, {
    variables: {
      doctorId: currentDoctorId,
      svnr: patientData?.svnr ?? '',
    },
    skip: !patientData?.svnr,
    fetchPolicy: 'no-cache',
  });
  const filteredRequests = (data?.patientRequests ?? []).filter(it => {
    if (!reordersOnly) {
      return true;
    }
    return it.reorderable;
  });

  const actionsAvailable = !requestIdForReorder && !requestIdForUpdate;

  const columns: ColumnsType<PatientRequests> = [
    {
      title: 'Erstellt am',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: value => dayjs(value).format(SHORT_DATETIME_FORMAT),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: value => <RequestStatusBadge status={value} />,
    },
    {
      title: 'Parameter',
      key: 'parameter',
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <TableList
          maxEntries={3}
          entries={record.orders
            .flatMap(o => o.orderParameters)
            .sort((a, b) =>
              flipParamNames ? a.longName.localeCompare(b.longName) : a.shortName.localeCompare(b.shortName)
            )
            .map(it => (flipParamNames ? it.longName : it.shortName))}
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => {
        return (
          <>
            {record.reorderable && actionsAvailable && (
              <Tooltip
                title={<span>bis {dayjs(record.reorderableUntil ?? undefined).format(SHORT_DATETIME_FORMAT)}</span>}
                placement="left"
              >
                <Button
                  type="link"
                  icon={<PlusCircleOutlined />}
                  onClick={e => {
                    e.stopPropagation();
                    const patientData = stripTypename(record.patientData);
                    if (patientData.insuredPerson) {
                      patientData.insuredPerson = stripTypename(patientData.insuredPerson);
                    }
                    onReorder(record.id, patientData);
                  }}
                >
                  Nachforderung
                </Button>
              </Tooltip>
            )}
            {(record.status === RequestStatus.NEW || record.status === RequestStatus.PRINTED) && actionsAvailable && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={e => {
                  e.stopPropagation();
                  onUpdate(record.id);
                }}
              >
                Bearbeiten
              </Button>
            )}
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={e => {
                e.stopPropagation();
                setDetailsRequestId(record.id);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table<PatientRequests>
        scroll={{ x: 'max-content' }}
        showSorterTooltip={false}
        rowKey={record => record.id}
        size="small"
        sticky
        className={css`
          overflow-y: auto;
        `}
        dataSource={filteredRequests}
        pagination={false}
        loading={loading || processing}
        columns={columns}
        onRow={record => ({
          onClick: () => {
            if (reordersOnly) {
              return;
            }
            const inputs: ParameterToAddInput[] = [];
            const unavailableParams: string[] = [];
            record.orders.forEach(order => {
              order.orderParameters.forEach(param => {
                const reqParam = params.find(it => it.lab.id === order.lab.id && it.shortName === param.shortName);
                if (reqParam) {
                  inputs.push({
                    parameterId: reqParam.id,
                    preferredBillingType: param.billingType,
                    preferredDiagnoseIds: [],
                  });
                } else {
                  unavailableParams.push(flipParamNames ? param.longName : param.shortName);
                }
              });
            });

            if (unavailableParams.length) {
              showParamWarning(
                notification,
                'Parameter nicht verfügbar',
                'Folgende Parameter stehen nicht zur Verfügung:',
                unavailableParams
              );
            }

            onAdd(inputs);
          },
        })}
        rowClassName={reordersOnly ? '' : tableHoverPointer}
      />
      <RequestDetailsModal requestId={detailsRequestId} onClose={() => setDetailsRequestId(null)} />
    </>
  );
};
