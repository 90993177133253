import { useAuth } from 'react-oidc-context';

interface PrintEmptyLabelPayload {
  doctorId: string;
  patientTitle: string;
  patientLastName: string;
  patientFirstName: string;
  patientSvnr: string;
}

export const usePrintEmptyLabel = (): ((
  payload: PrintEmptyLabelPayload,
  count: number,
  onError: () => void,
  onSuccess: () => void
) => void) => {
  const auth = useAuth();

  return async (payload: PrintEmptyLabelPayload, count: number, onError: () => void, onSuccess: () => void) => {
    const url =
      window._env_.API_URL +
      '/rest/empty-label?' +
      new URLSearchParams({
        doctorId: payload.doctorId,
        patientTitle: payload.patientTitle,
        patientLastName: payload.patientLastName,
        patientFirstName: payload.patientFirstName,
        patientSvnr: payload.patientSvnr,
      }).toString();

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${auth.user?.access_token}`,
        },
      });

      if (!response.ok) {
        onError();
        return;
      }

      const blob = await response.blob();
      const buffer = await blob.arrayBuffer();
      let concatenatedLabels = new ArrayBuffer(0);
      for (let index = 0; index < count; index++) {
        concatenatedLabels = await new Blob([concatenatedLabels, buffer]).arrayBuffer();
      }
      if (concatenatedLabels.byteLength > 0) {
        await window.nativeApi?.print(buffer, 'label');
      }
      onSuccess();
    } catch {
      onError();
    }
  };
};
