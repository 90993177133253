import { useMutation } from '@apollo/client';
import { graphql } from '../graphql/generated';
import { useAuth } from 'react-oidc-context';

const MARK_PRINTED_MUTATION = graphql(`
  mutation MarkPrinted($requestId: ID!, $beforeSend: Boolean!) {
    markPrinted(requestId: $requestId, beforeSend: $beforeSend) {
      id
    }
  }
`);

type PrintableRequest = {
  id: string;
  orders: Array<{
    orderLabels: Array<{
      id: string;
      printedAt?: string | null;
      specimenId: string;
      testTubeId: string;
      location: string;
    }>;
    orderFiles: Array<{
      id: string;
      printable: boolean;
      printRequired: boolean;
      printedAt?: string | null;
      printBeforeSend: boolean;
    }>;
  }>;
};

export const usePrintRequest = (): ((
  mode: 'before-send' | 'send',
  request: PrintableRequest,
  onError: () => void,
  onNoPrint: () => void,
  onSuccess: () => void,
  onMarkError: () => void,
  onRefetch: () => void
) => void) => {
  const auth = useAuth();
  const [markPrintedMutation] = useMutation(MARK_PRINTED_MUTATION);

  return async (
    mode: 'before-send' | 'send',
    request: PrintableRequest,
    onError: () => void,
    onNoPrint: () => void,
    onSuccess: () => void,
    onMarkError: () => void,
    onRefetch: () => void
  ) => {
    const labels = request.orders
      .flatMap(it => it.orderLabels)
      .filter(it => !it.printedAt)
      .sort(
        (a, b) =>
          a.specimenId.localeCompare(b.specimenId) ||
          a.testTubeId.localeCompare(b.testTubeId) ||
          a.location.localeCompare(b.location)
      );

    const files = request.orders
      .flatMap(it => it.orderFiles)
      .filter(it => it.printable)
      .filter(it => it.printRequired)
      .filter(it => !it.printedAt)
      .filter(it => (mode === 'before-send' ? it.printBeforeSend : true));

    let error = false;

    try {
      let concatenatedLabels = new ArrayBuffer(0);

      for (const label of labels) {
        const endpoint = window._env_.API_URL + `/rest/order-label/${label.id}/download`;
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          error = true;
          break;
        }

        const blob = await response.blob();
        const buffer = await blob.arrayBuffer();
        // merging the labels to one print request speeds up printing significantly
        concatenatedLabels = await new Blob([concatenatedLabels, buffer]).arrayBuffer();
      }

      if (concatenatedLabels.byteLength > 0) {
        await window.nativeApi?.print(concatenatedLabels, 'label');
      }
    } catch {
      error = true;
    }

    for (const file of files) {
      const endpoint = window._env_.API_URL + `/rest/order-file/${file.id}`;
      try {
        const response = await fetch(endpoint, {
          headers: {
            authorization: `Bearer ${auth.user?.access_token}`,
          },
        });

        if (!response.ok) {
          error = true;
          continue;
        }

        const blob = await response.blob();
        const buffer = await blob.arrayBuffer();
        await window.nativeApi?.print(buffer, 'a4');
      } catch {
        error = true;
      }
    }

    if (error) {
      onError();
      return;
    }

    if (labels.length + files.length < 1) {
      onNoPrint();
    } else {
      onSuccess();
    }

    try {
      if (window.nativeApi) {
        await markPrintedMutation({
          variables: {
            requestId: request.id,
            beforeSend: mode === 'before-send',
          },
        });
      }

      onRefetch();
    } catch {
      onMarkError();
    }
  };
};
