import React, { useEffect, useState } from 'react';
import { MainContent } from '../../components/MainContent';
import { useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { SheetEditor } from '../../components/sheet-editor/SheetEditor.tsx';
import { SheetDataWithId } from '../../components/sheet-editor/types';
import { App } from 'antd';
import { graphql } from '../../graphql/generated';
import { SheetData } from '../../graphql/sheet';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';

export const DOCTOR_SHEET_QUERY = graphql(`
  query DoctorSheet($doctorId: ID!) {
    doctorSheets(doctorId: $doctorId, withLabSheets: false) {
      id
      data
    }

    catalogParameters(doctorId: $doctorId) {
      id
      parameter {
        ...SheetEditor_Parameter
      }
    }

    doctor(id: $doctorId) {
      id
      flipParamNames
    }
  }
`);

const SAVE_DOCTOR_SHEET_MUTATION = graphql(`
  mutation SaveDoctorSheet($doctorId: ID!, $input: SheetInput!) {
    saveDoctorSheet(doctorId: $doctorId, input: $input) {
      id
    }
  }
`);

export const Sheets: React.FC = () => {
  const { message } = App.useApp();
  const { currentDoctorId } = useCurrentContextStore();
  const [sheetData, setSheetData] = useState<SheetDataWithId[]>([]);

  const { data, loading } = useQuery(DOCTOR_SHEET_QUERY, {
    variables: {
      doctorId: currentDoctorId ?? '',
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const [sheet] = data?.doctorSheets ?? [];
    if (sheet) {
      setSheetData(
        (sheet.data as SheetData[]).map(sheet => ({
          ...sheet,
          id: uuidv4(),
          groups: sheet.groups.map(group => ({
            ...group,
            id: uuidv4(),
            parameters: group.parameters.map(param => ({
              ...param,
              paramId: param.id,
              id: uuidv4(),
            })),
          })),
        }))
      );
    }
  }, [data]);

  const [saveDoctorSheetMutation] = useMutation(SAVE_DOCTOR_SHEET_MUTATION);

  const save = async (data: SheetDataWithId[]) => {
    try {
      await saveDoctorSheetMutation({
        variables: {
          doctorId: currentDoctorId,
          input: {
            data: data.map(it => ({
              name: it.name,
              groups: it.groups.map(it => ({
                name: it.name,
                parameterIds: it.parameters.map(it => it.paramId),
              })),
            })),
          },
        },
      });
    } catch {
      message.error('Das Sheet konnte nicht gespeichert werden');
    }
  };

  const autoSave = (sheets: SheetDataWithId[]) => {
    setSheetData(sheets);
    save(sheets);
  };

  return (
    <MainContent size="medium">
      <SheetEditor
        title="Sheets"
        loading={loading}
        sheets={sheetData}
        onChange={autoSave}
        showLab
        flipParamNames={data?.doctor?.flipParamNames ?? false}
        parameters={data?.catalogParameters?.map(it => it.parameter) ?? []}
      />
    </MainContent>
  );
};
