import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { translateBillingType } from '../../utils/enumHelpers';
import { LgBadge } from '../../components/LgBadge';
import { currency } from '../../utils/currency';
import { BillingInfo, BillingType } from '../../graphql/generated/graphql.ts';

export const BillingInfoComponent: React.FC<{ billingInfo: BillingInfo; showPrice?: boolean }> = ({
  billingInfo,
  showPrice = true,
}) => {
  switch (billingInfo.billingType) {
    case BillingType.SPECIAL_RATE:
    case BillingType.INSURANCE:
      return <>{billingInfo.text}</>;
    case BillingType.LG_INSURANCE:
      return (
        <>
          <LgBadge /> {billingInfo.text}
        </>
      );
    case BillingType.DIAGNOSE:
      return (
        <Tooltip title={billingInfo.text}>
          <InfoCircleOutlined /> Diagnose - {billingInfo.additionalText}
        </Tooltip>
      );
    case BillingType.LG_DIAGNOSE:
      return (
        <Tooltip title={billingInfo.text}>
          <LgBadge /> <InfoCircleOutlined /> Diagnose - {billingInfo.additionalText}
        </Tooltip>
      );
    case BillingType.LG_DOCTOR:
      return (
        <>
          <LgBadge /> Zuweiser {showPrice && currency(billingInfo.price)}
        </>
      );
    default:
      return (
        <>
          {translateBillingType(billingInfo.billingType)} {showPrice && currency(billingInfo.price)}
        </>
      );
  }
};
