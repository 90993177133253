import React, { useEffect, useState } from 'react';
import { App, Form, Input, Modal } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { DoctorSelect } from '../../../components/DoctorSelect';
import { graphql } from '../../../graphql/generated';
import { Doctor, Profile } from '../Profiles.tsx';

const CREATE_PROFILE_MUTATION = graphql(`
  mutation CreateProfile($input: ProfileInput!) {
    createProfile(input: $input) {
      id
    }
  }
`);

export const CopyProfileModal: React.FC<{
  doctors: Doctor[];
  currentDoctorId: string;
  profile: Profile | null;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ doctors, currentDoctorId, profile, onClose, onSuccess }) => {
  const [createProfileMutation, { loading }] = useMutation(CREATE_PROFILE_MUTATION);
  const [name, setName] = useState<string>('');
  const [doctorId, setDoctorId] = useState<string>(currentDoctorId);
  const { message } = App.useApp();

  useEffect(() => {
    setName(profile?.name ?? '');
  }, [profile]);

  const copy = async () => {
    if (!name.trim().length) {
      message.warning('Bitte geben Sie eine Bezeichnung an');
      return;
    }

    try {
      await createProfileMutation({
        variables: {
          input: {
            doctorId: doctorId,
            name: name,
            hotKey: null,
            parameters:
              profile?.parameters.map(it => ({
                parameterId: it.parameter.id,
                preferredBillingType: it.preferredBillingType,
              })) ?? [],
            diagnose: profile?.diagnose ?? '',
            diagnoseIds: profile?.diagnoses.map(it => it.id) ?? [],
          },
        },
      });

      message.success('Profil wurde kopiert');
      onSuccess();
    } catch {
      message.error('Profil konnte nicht kopiert werden');
    }
  };

  return (
    <Modal
      width={500}
      title={`Profil ${profile?.name} kopieren`}
      okText="Kopieren"
      okButtonProps={{ icon: <CopyOutlined /> }}
      open={!!profile}
      onCancel={onClose}
      onOk={copy}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form layout="vertical">
        <Form.Item label="Neue Bezeichnung" required>
          <Input value={name} onChange={e => setName(e.target.value)} />
        </Form.Item>
        {doctors.length > 1 && (
          <Form.Item label="Kopieren zu Zuweiser">
            <DoctorSelect
              style={{ width: '100%' }}
              onChange={value => setDoctorId(value as string)}
              doctors={doctors}
              selected={doctorId}
              allowClear={false}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
