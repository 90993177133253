import React, { useEffect, useState } from 'react';
import {
  App,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Form as AntForm,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  theme,
  Tooltip,
} from 'antd';
import { useMutation } from '@apollo/client';
import { useForm } from 'antd/es/form/Form';
import {
  DeleteOutlined,
  DownCircleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import { translateFormCustomizer, translateFormFieldType } from '../../../../utils/enumHelpers';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/css';
import { FormPreviewModal } from './FormPreviewModal';
import { graphql } from '../../../../graphql/generated';
import { Form } from '../Forms.tsx';
import { FormCustomizer, FormFieldType, FormInput } from '../../../../graphql/generated/graphql.ts';

const { Option } = Select;

const UPDATE_FORM_MUTATION = graphql(`
  mutation UpdateForm($id: ID!, $input: FormInput!) {
    updateForm(id: $id, input: $input) {
      id
    }
  }
`);

export const FormEditorDrawer: React.FC<{
  form: Form | null;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ form, onClose, onSuccess }) => {
  const { token } = theme.useToken();
  const [updateFormMutation, { loading }] = useMutation(UPDATE_FORM_MUTATION);
  const [formInstance] = useForm<FormInput>();
  const [preview, setPreview] = useState<FormInput | null>(null);
  const { message } = App.useApp();

  const update = async (values: FormInput) => {
    const duplicates: string[] = [];
    for (const name of values.fields.map(it => it.name)) {
      if (duplicates.includes(name)) {
        message.error('Das Formular enthält mind. 2 Felder mit gleicher Kennzeichnung');
        return;
      }
      duplicates.push(name);
    }

    try {
      await updateFormMutation({
        variables: {
          id: form?.id ?? '',
          input: {
            ...values,
            labId: uuidv4(), // unused here, use random
          },
        },
      });

      message.success('Formular wurde bearbeitet');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Formular konnte nicht bearbeitet werden');
    }
  };

  const closeAndReset = () => {
    formInstance.resetFields();
    onClose();
  };

  useEffect(() => {
    formInstance.setFieldsValue({
      name: form?.name,
      header: form?.header,
      description: form?.description,
      pickup: form?.pickup,
      printRequired: form?.printRequired,
      printBeforeSend: form?.printBeforeSend,
      direct: form?.direct,
      restore: form?.restore,
      customizer: form?.customizer,
      fields:
        form?.fields.map(field => ({
          name: field.name,
          label: field.label,
          placeholder: field.placeholder,
          required: field.required,
          help: field.help,
          defaultValue: field.defaultValue,
          regex: field.regex,
          type: field.type,
          options: field.options.map(option => ({
            label: option.label,
            value: option.value,
            selected: option.selected,
          })),
        })) ?? [],
    });
  }, [formInstance, form]);

  return (
    <Drawer
      title="Formular bearbeiten"
      forceRender
      width="60%"
      rootClassName={css`
        .ant-drawer-content-wrapper {
          max-width: 1200px;
          min-width: 800px;
        }
      `}
      placement="right"
      onClose={onClose}
      open={!!form}
      extra={
        <Space direction="horizontal">
          <Button onClick={() => setPreview(formInstance.getFieldsValue())} icon={<EyeOutlined />}>
            Vorschau
          </Button>
          <Button type="primary" onClick={formInstance.submit} icon={<SaveOutlined />} disabled={loading}>
            Speichern
          </Button>
        </Space>
      }
    >
      <AntForm<FormInput> form={formInstance} layout="vertical" onFinish={update} name="update-form">
        <AntForm.Item name="name" label="Bezeichnung" rules={[{ required: true, whitespace: true }]}>
          <Input />
        </AntForm.Item>
        <AntForm.Item name="header" label="Überschrift">
          <Input />
        </AntForm.Item>
        <AntForm.Item name="description" label="Beschreibung">
          <Input.TextArea rows={5} />
        </AntForm.Item>
        <AntForm.Item name="customizer" label="Customizer" rules={[{ required: true }]}>
          <Select>
            {Object.keys(FormCustomizer).map(fc => {
              return (
                <Option key={fc} value={fc}>
                  {translateFormCustomizer(fc)}
                </Option>
              );
            })}
          </Select>
        </AntForm.Item>
        <Space direction="vertical" className={css(`margin-bottom: ${token.marginMD}px`)}>
          <AntForm.Item name="printRequired" valuePropName="checked" noStyle>
            <Checkbox>Dokument drucken</Checkbox>
          </AntForm.Item>
          <AntForm.Item name="printBeforeSend" valuePropName="checked" noStyle>
            <Checkbox>Dokument vor dem Senden drucken</Checkbox>
          </AntForm.Item>
          <AntForm.Item name="pickup" valuePropName="checked" noStyle>
            <Checkbox>Dokument elektronisch übertragen</Checkbox>
          </AntForm.Item>
          <AntForm.Item name="direct" valuePropName="checked" noStyle>
            <Checkbox>
              Direkt{' '}
              <Tooltip title="Ohne Anforderung mit Patienten- und Formulardaten erstellbar">
                <InfoCircleOutlined />
              </Tooltip>
            </Checkbox>
          </AntForm.Item>
          <AntForm.Item name="restore" valuePropName="checked" noStyle>
            <Checkbox>
              Wiederherstellen{' '}
              <Tooltip title="Beim Wiederholen einer Anforderung die Formularfelder wiederherstellen">
                <InfoCircleOutlined />
              </Tooltip>
            </Checkbox>
          </AntForm.Item>
        </Space>

        <AntForm.List name="fields">
          {(fieldFields, { add: addField, remove: removeField, move: moveField }, { errors: fieldErrors }) => (
            <Space direction="vertical" style={{ width: '100%' }}>
              {fieldFields.map(({ key, name, ...restField }, index) => (
                <AntForm.Item
                  noStyle
                  shouldUpdate={(prevValues, nextValues) =>
                    prevValues.fields[index]?.type !== nextValues.fields[index]?.type
                  }
                  key={key}
                >
                  {form => {
                    const value = form.getFieldsValue();
                    return (
                      <Card
                        size="small"
                        headStyle={{ backgroundColor: token.colorBgLayout }}
                        title={translateFormFieldType(value.fields[index].type)}
                        extra={
                          <>
                            <Button
                              disabled={index <= 0}
                              size="small"
                              icon={<UpCircleOutlined />}
                              type="text"
                              onClick={() => moveField(index, index - 1)}
                            />
                            <Button
                              disabled={index >= fieldFields.length - 1}
                              size="small"
                              icon={<DownCircleOutlined />}
                              type="text"
                              onClick={() => moveField(index, index + 1)}
                            />
                            <Popconfirm
                              placement="left"
                              title="Feld wirklich löschen?"
                              onConfirm={() => removeField(index)}
                              okText="Ja"
                              okButtonProps={{ danger: true }}
                              cancelText="Nein"
                            >
                              <Button danger size="small" icon={<DeleteOutlined />} type="text" />
                            </Popconfirm>
                          </>
                        }
                        style={{ width: '100%' }}
                      >
                        <Row gutter={[16, 0]}>
                          <Col span={8}>
                            <AntForm.Item
                              {...restField}
                              name={[name, 'name']}
                              rules={[
                                { required: true, whitespace: true },
                                { pattern: /^[a-z0-9-]+$/, message: 'Nur Kleinbuchstaben, Zahlen und - erlaubt' },
                              ]}
                              label="Kennzeichnung"
                            >
                              <Input />
                            </AntForm.Item>
                          </Col>
                          <Col span={8}>
                            <AntForm.Item {...restField} name={[name, 'label']} label="Label">
                              <Input />
                            </AntForm.Item>
                          </Col>
                          <Col span={8}>
                            <AntForm.Item {...restField} name={[name, 'placeholder']} label="Platzhalter">
                              <Input
                                disabled={
                                  value.fields[index].type === FormFieldType.OPTION_GROUP ||
                                  value.fields[index].type === FormFieldType.CHECKBOX_GROUP
                                }
                              />
                            </AntForm.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                          <Col span={8}>
                            <AntForm.Item {...restField} name={[name, 'help']} label="Hilfetext">
                              <Input />
                            </AntForm.Item>
                          </Col>
                          <Col span={5}>
                            <AntForm.Item {...restField} name={[name, 'defaultValue']} label="Default-Wert">
                              <Input disabled={value.fields[index].type !== FormFieldType.INPUT} />
                            </AntForm.Item>
                          </Col>
                          <Col span={3}>
                            <AntForm.Item
                              {...restField}
                              name={[name, 'required']}
                              label="Pflichtfeld"
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </AntForm.Item>
                          </Col>
                          <Col span={8}>
                            <AntForm.Item
                              {...restField}
                              name={[name, 'regex']}
                              label="Regexp"
                              rules={[{ type: 'regexp', message: 'Regexp ungültig' }]}
                              tooltip="Regulärer Ausdruck zur Validierung der Eingabe. Bsp.: ^\d+$ für nur numerische Werte"
                            >
                              <Input disabled={value.fields[index].type !== FormFieldType.INPUT} />
                            </AntForm.Item>
                          </Col>
                        </Row>
                        {(value.fields[index].type === FormFieldType.SELECT ||
                          value.fields[index].type === FormFieldType.OPTION_GROUP ||
                          value.fields[index].type === FormFieldType.CHECKBOX_GROUP) && (
                          <>
                            <Divider orientation="left" style={{ marginTop: 0 }}>
                              Optionen
                            </Divider>
                            <AntForm.List
                              name={[name, 'options']}
                              rules={[
                                {
                                  validator: (_, values) => {
                                    if (!values || values.length < 1) {
                                      return Promise.reject('Mind. eine Option angeben');
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              {(
                                optionFields,
                                { add: addOption, remove: removeOption, move: moveOption },
                                { errors: optionErrors }
                              ) => (
                                <Space direction="vertical" style={{ width: '100%' }}>
                                  {optionFields.map(({ key, name, ...restField }, index) => (
                                    <Row key={key} gutter={[16, 0]}>
                                      <Col span={8}>
                                        <AntForm.Item
                                          {...restField}
                                          name={[name, 'label']}
                                          rules={[{ required: true, whitespace: true }]}
                                          label="Label"
                                        >
                                          <Input />
                                        </AntForm.Item>
                                      </Col>
                                      <Col span={8}>
                                        <AntForm.Item
                                          {...restField}
                                          name={[name, 'value']}
                                          rules={[{ required: true, whitespace: true }]}
                                          label="Wert"
                                        >
                                          <Input />
                                        </AntForm.Item>
                                      </Col>
                                      <Col span={4}>
                                        <AntForm.Item
                                          {...restField}
                                          name={[name, 'selected']}
                                          label="Vorausgewählt"
                                          valuePropName="checked"
                                        >
                                          <Checkbox />
                                        </AntForm.Item>
                                      </Col>
                                      <Col span={4}>
                                        <Button
                                          disabled={index <= 0}
                                          size="small"
                                          icon={<UpCircleOutlined />}
                                          type="text"
                                          onClick={() => moveOption(index, index - 1)}
                                        />
                                        <Button
                                          disabled={index >= optionFields.length - 1}
                                          size="small"
                                          icon={<DownCircleOutlined />}
                                          type="text"
                                          onClick={() => moveOption(index, index + 1)}
                                        />
                                        <Button
                                          danger
                                          size="small"
                                          icon={<DeleteOutlined />}
                                          type="text"
                                          onClick={() => removeOption(index)}
                                        />
                                      </Col>
                                    </Row>
                                  ))}
                                  <Button
                                    icon={<PlusCircleOutlined />}
                                    onClick={() =>
                                      addOption({
                                        label: '',
                                        value: '',
                                        selected: false,
                                      })
                                    }
                                  >
                                    Option hinzufügen
                                  </Button>
                                  <AntForm.ErrorList
                                    className={css`
                                      color: ${token.colorError};
                                    `}
                                    errors={optionErrors}
                                  />
                                </Space>
                              )}
                            </AntForm.List>
                          </>
                        )}
                      </Card>
                    );
                  }}
                </AntForm.Item>
              ))}
              <Dropdown
                menu={{
                  items: Object.keys(FormFieldType).map(type => ({
                    key: type,
                    label: translateFormFieldType(type),
                    onClick: () =>
                      addField({
                        name: '',
                        label: '',
                        placeholder: '',
                        required: false,
                        help: '',
                        defaultValue: '',
                        regex: '',
                        type: type,
                        options: [],
                      }),
                  })),
                }}
                placement="bottomLeft"
                trigger={['click']}
              >
                <Button icon={<PlusCircleOutlined />}>Feld hinzufügen</Button>
              </Dropdown>
              <AntForm.ErrorList
                className={css`
                  color: ${token.colorError};
                `}
                errors={fieldErrors}
              />
            </Space>
          )}
        </AntForm.List>
      </AntForm>
      <FormPreviewModal
        header={preview?.header ?? ''}
        teaser={preview?.description ?? ''}
        fields={preview?.fields ?? []}
        open={preview !== null}
        onClose={() => setPreview(null)}
      />
    </Drawer>
  );
};
